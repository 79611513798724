<template>
  <div class="md-layout md-alignment-center-center">
    <div class="md-layout md-size-100 week-lines" v-if="isLoading">
        <div class="md-layout-item no-data-container">
          <p>
          <md-progress-bar class="md-accent" md-mode="indeterminate">
            Loading ... </md-progress-bar>
          </p>
        </div>
    </div>
    <div v-else class="md-layout-item md-size-75 md-medium-size-80 md-small-size-100 week-lines">
      <CategoryFaves :categoryName="categoryName" :year="year"></CategoryFaves>
    </div>
  </div>
</template>

<script>
import CategoryFaves from '@/components/common/CategoryFaves.vue';
import { FETCH_FAV_PAGE, RESET_FAV_PAGE } from '@/store/modules/favPage/favPageActions.type';
import loaderMixin from '@/mixins/loaderMixin';

export default {
  name: 'WeekleyPage',
  components: {
    CategoryFaves,
  },
  mixins: [
    loaderMixin,
  ],
  props: {
    categoryName: {
      type: String,
      default: null,
    },
    params: {
      type: String,
      default: null,
    },
    year: {
      type: [String, Number],
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_FAV_PAGE).then(() => {
      next();
    });
  },
  methods: {
    fetchFavPage(id) {
      this.$store.dispatch(FETCH_FAV_PAGE, id)
        .then((response) => {
          this.$log.info('FETCH_FAV_PAGE', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_FAV_PAGE', error);
        });
    },
  },
  watch: {
    year: {
      handler(val) {
        if (val) {
          this.fetchFavPage(`week${val}.allmyfaves.com`);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
